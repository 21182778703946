<template>

    

    <div style="padding:20px;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 style="color:blue;" >Prioridades Planejadas</h1>
                </div>
                <br>
                <div style="font-size:18px;">
                    <h3 >Ultima Reunião de Alinhamento de Prioridades: </h3>
                    <div style="padding-left:20px;">
                        <p>- Construido no dia 10/06/2024 as 10:00, participaram da Reunião Gianluca, Karynne e Hérlessi</p>
                        <p>- Atualizado no dia 26/06/2024 as 11:00, Remoção da prioridade Rede a pedido de Gianluca durante meet dev na semana dos dia 17/06/2024 a 21/06/2024 devido o cancelamento do hotel florença</p>
                        <p>- Atualizado no dia 05/08/2024 finalizado as 12h, Nova ordem de Prioridades, meet com Herlessi, Sérgio e Gianluca</p>
                    </div>
                </div>



                <div style="margin-top:40px;margin-left:30px;font-size:18px;">

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">1 - Montar Sprint integração Erbon ( FD Guest - Herlessi )</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">2 - Montar Sprint Camareiras (será realizado com Free lancer)</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">3 - Analisar documentos Espot e Montar Estrutura de implantação</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>


                        <div style="margin-top:30px;color:#b0b000;" >
                            <h3 stye="margin-bottom:20px!important;">4 - Stone - Pagar.me Connect(Herlessi)</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;">O teste do Modo Indireto: Sucesso!</p>
                                <p style="margin-top:10px;">Teste do modo direto pendente: Pois o site do pagar.me tem um bug que não permite a troca, precisa abrir chamado.</p>
                                <p style="margin-top:10px;">Msg de Felipe do Pagar.me( no Hub você tem acesso ao nosso chat para dúvidas e ajuda, no canto inferior direito ele tem um balão que abre atendimento aqui com o time ).</p>
                            </div>
                        </div>

                        

                        

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">5 - Integração Stone para Check in Pay e Out (Herlessi)</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">6 - Desenvolvimento integração Erbon ( FD Guest - Herlessi )</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">7 - Continuar integração J.Atlantico ( <span style="color:#b0b000">Cardapio - Rafhael</span> / FD Guest Rafhael  )</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">8 - Ficha Light (João)</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;">Em reunião com Equipe de Marketing no dia 13/06/2024 as 14h, ficou definido que a prioridade atual da equipe é concluir
                                    o projeto figma do ficha digital e somente depois entrará para finalizar o layout do Ficha Light.
                                </p>
                                <p style="margin-top:10px;">
                                    A expectativa é que o layout do ficha light fique pronto na semana de 17/06 a 21/06
                                </p>
                            </div>
                        </div>

                        <div style="margin-top:30px;color:#b0b000;">
                            <h3 stye="margin-bottom:20px!important;">9 - Cardapio Off Line (Rafhael - Adicionar Jenkins)</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"><span style="color:#26c726;">Concluida em 26/06/2024 - 1 - Roadmap de Mesas (Herlessi e Rafhael)</span></h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"><span style="color:#26c726;"> Concluido - 5 - Desconto Automáticos no cardapio (Herlessi)</span></h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"><span style="color:red;">(Prioridade Removida) 2 - Rede(Herlessi): A rede, estamos trabalhando no escuro</span></h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;color:red;">Motivo 1: - A api não é muito clara em java ;</p>
                                <p style="margin-top:10px;color:red;">Motivo 2: - Não há suporte da equipe Rede, o suporte sempre nega dar qualquer resposta;</p>
                                <p style="margin-top:10px;color:red;">Motivo 3: - Vou até concluir mas não sei se conseguirei testar, pois não vejo ambiente de teste e se quando subir conseguirei aprovação;</p>
                            </div>
                        </div>

                        

                        

                        


                        

                        

                        
                   
                       

                        

                        

                        <!-- <hr>
                        <hr>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">8 - Continuar integração Erbon ( FD Guest )</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">9 - Integração New Hotel ( Fazenda santa barbara )</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">10 - Alterar Layout do ficha para Tela unica (Configuravel por Cliente)</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">11 - Atualização Layout Ficha </h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">12 - Atualização Layout Cardapio </h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">13 - Atualização Layout FD Guest </h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;">14 - Pontua</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div> -->
                   

                </div>
               





            </v-card-text>
               
        </v-card>



        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 style="color:red;" >Prioridades Não Planejadas</h1>
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">

                        

                        

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Integração New Hotel ( Fazenda santa barbara )</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Integração Opera</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Alterar Layout do ficha para Tela unica (Configuravel por Cliente)</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Atualização Layout Ficha </h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Atualização Layout Cardapio </h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Atualização Layout FD Guest </h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div>

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Pontua</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div> 

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Sistema de Fidelidade no Ficha Digital</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div> 

                        <div style="margin-top:30px;" >
                            <h3 stye="margin-bottom:20px!important;"> - Formato de Pagamento Whatsapp</h3>
                            <div style="padding-left:20px;">
                                <p style="margin-top:10px;"></p>
                            </div>
                        </div> 
                   

                </div>
               





            </v-card-text>
               
        </v-card>


    </div>

    
</template>

<script>

export default {
    name:'Parceiros',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style scoped>


    .paragrafo {
        margin-bottom:20px!important;
    }
</style>